@import "./micropython.scss";
@import "./syncfusion.scss";
@import "./fileuploader.scss";

.container {
  height: 100vh;
  width: 100vw;
  font-family: Helvetica;
}

.loader {
  height: 20px;
  width: 250px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.loaderDot {
  animation-name: loader;
  animation-timing-function: ease-in-out;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background-color: #00a2e6;
  position: absolute;
  border: 1px solid #00a2e6;
}

.loaderDot:first-child {
  background-color: "black";
  animation-delay: 0.5s;
  border: "black";
}

.loaderDot:nth-child(2) {
  background-color: #00c849;
  border: #00c849;
  animation-delay: 0.4s;
}

.loaderDot:nth-child(3) {
  background-color: #e08746;
  animation-delay: 0.3s;
  border: #e08746;
}

.loaderDot:nth-child(4) {
  background-color: #d74357;
  animation-delay: 0.2s;
  border: #d74357;
}

.loaderDot:nth-child(5) {
  background-color: #78416b;
  animation-delay: 0.1s;
  border: #78416b;
}

.loader--text {
  position: absolute;
  top: 200%;
  left: 0;
  right: 0;
  width: 4rem;
  margin: auto;
}

.loader--text:after {
  content: "Loading";
  font-weight: bold;
  animation-name: loading-text;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

@keyframes loader {
  15% {
    transform: translateX(0);
  }

  45% {
    transform: translateX(230px);
  }

  65% {
    transform: translateX(230px);
  }

  95% {
    transform: translateX(0);
  }
}

@keyframes loading-text {
  0% {
    content: "Loading";
  }

  25% {
    content: "Loading.";
  }

  50% {
    content: "Loading..";
  }

  75% {
    content: "Loading...";
  }
}






@keyframes popping-animation {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}