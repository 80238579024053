@import url("https://fonts.googleapis.com/css2?family=Public+Sans:wght@300&display=swap");
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
    border: none;
    color: inherit;
    background: transparent;
    text-decoration: none;
    /* transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1); */
    word-wrap: break-word;
    position: relative;
    font-family: "Public Sans", sans-serif;
    /* outline: 1px solid gray; */
}
*::-webkit-scrollbar {
    width: 2px;
}
*::-webkit-scrollbar-track {
    background: #f1f1f105;
}
*::-webkit-scrollbar-thumb {
    background: #d2d2d2;
    border-radius: 10px;
}
*::-webkit-scrollbar-thumb:hover {
    background: gray;
}

body {
    min-height: 95vh;
}

button,
span,
a,
li,
label,
svg,
i,
img {
    cursor: pointer;
}

pre{
    white-space: pre-wrap;
    word-wrap: break-word;
}