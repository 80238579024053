#monitor-designer-grid-stack .grid-stack {
    /* background-color: #eeeeee; */
    background-image:
        linear-gradient(#e0e0e0 1px, transparent 1px),
        linear-gradient(90deg, #e0e0e0 1px, transparent 0px),
        linear-gradient(rgba(255, 255, 255, .3) 5px, transparent 130px),
        linear-gradient(90deg, rgba(255, 255, 255, .3) 5px, transparent 130px);
    background-size:
        130px 62px,
        calc(8.33% + 0px) 130px,
        20px 20px,
        20px 20px;
    background-position:
        -2px -2px,
        -1px -2px,
        -1px -1px,
        -1px -1px;
}