.blocklyTreeIcon{
    /* visibility: visible !important; */
    box-shadow: inset -3px -3px 7px rgba(0, 0, 0, .5) !important;
    border-radius: 3px;
    margin: 3px;
}

.blocklyTreeRow{
    border: none !important;
    padding: 10px 0;
    height: auto;
}

