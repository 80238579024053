.micro-python-editor {
    width: 100%;
    max-height: 99vh;
    height: 100%;
    min-width: 49vw;
}

@media screen and (max-width: 768px) {
    .micro-python-editor {
        width: 100vw;
    }
}
